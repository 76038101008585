import DataTable from "Components/Table/Index";
import moment from "moment";
import { useEffect, useState } from "react";
import { useActivityLogQuery } from "store/Actions/adminAction";

import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import { generateDateRanges } from "utils/functions";
import MoreInfoModal from "pages/BoatInquiry/MoreInfoModal";
const ActivityLog = () => {
  const dateRanges = generateDateRanges();
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [filter, setFilter] = useState({
    sort: "created_at",
    order: "desc",
    perPage: 50,
    page: 1,
    start_date: ``,
    end_date: ``,
  });
  let [moreInfoIsOpen, setMoreInfoIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const setDate = (index) => {
    setSelectedBtn(index);
  };
  function closeModal() {
    setMoreInfoIsOpen(false);
  }
  function showMoreInfoModal(data) {
    setModalData(data);
    setMoreInfoIsOpen(true);
  }

  useEffect(() => {
    const selectedRange = generateDateRanges().find((item) => item.index === selectedBtn); // Use `find` for a single match    
    if (selectedRange) {
      setFilter((filter) => ({ ...filter,page:1, start_date: selectedRange.start_date, end_date: selectedRange.end_date }));
    }
  }, [selectedBtn]);

  const { data, isLoading, isFetching } = useActivityLogQuery(filter, { skip: filter.start_date === `` });
  const columns = [
    {
      header: "Description",
      accessor: "description",
    },
    {
      header: "User Name",
      accessor: "name",
      Cell: ({ cell: { row } }) => row.original.user.name,
    },
    {
      header: "IP Address",
      accessor: "ip",
      Cell: ({ cell: { row } }) => row.original.ip_address,
    },
    {
      header: "User Agent",
      accessor: "user_agent",
      Cell: ({ cell: { value } }) => {
        const truncatedMessage = value.split('\n').join(' ').replace(/\s{2,}/g, '\n').substring(0, 15);
        const isTruncated = value.length > 15;
        return (
          <div className="flex flex-col w-full">
            <div className="whitespace-pre-wrap">
              {isTruncated ? truncatedMessage : value}
              {isTruncated && (
                <span
                  className="text-blue-500 cursor-pointer ml-2"
                  onClick={() => {
                    showMoreInfoModal(value);
                  }}
                >
                  ...More
                </span>
              )}
            </div>
          </div>
        );
      },
    },
    {
      header: "Created At",
      accessor: "created_at",
      Cell: ({ cell: { value } }) => {
        return (
          <span className={``}>
            {moment(value).format("MM-DD-YYYY HH:mm:ss")}
          </span>
        );
      },
    },
  ];

  return (
    <div className="flex flex-wrap my-3 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 break-words bg-white p-6 border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6  pb-0">
            <div className="lg:flex ">
              <div className="flex gap-4 p-6 pb-0 pt-1 max-w-3/12">
                <h5 className="mb-2 dark:text-white font-bold text-3xl text-blue-800">
                  Activity Logs
                </h5>
              </div>
              <div className="flex gap-4 pl-6 pb-0 pt-1 max-w-9/12">
                {dateRanges.map((range,) => (
                  <span
                    key={range.text}
                    onClick={() => setDate(range.index)}
                    className={`w-auto rounded-lg p-4 text-sm font-medium leading-5 inline-block px-4 py-2 m-0 text-sm font-bold text-center transition-all cursor-pointer border-1 ${(selectedBtn === range.index ? "text-white border-blue-100 bg-gradient-to-tl from-blue-600 to-blue-800" : "hover:bg-gray-100 hover:text-gray border-gray")}`}
                  >
                    {range.text}
                  </span>
                ))}
              </div>
            </div>
            <DataTable
              ListQuery={{ data: data?.data, isLoading, isFetching }}
              filterProps={{ filter, setFilter }}
              columns={columns}
              sortingField={["description", "name", "user_agent", "created_at"]}
              searchFields={["name", "ip"]}
            />
          </div>
        </div>
        <MoreInfoModal
        closeModal={closeModal}
        isOpen={moreInfoIsOpen}
        modalData={modalData}
      />
      </div>
    </div>
  );
};

export default ActivityLog;
