import SubmitButton from "Components/Butttons/SubmitButton";
import ComponentLoader from "Components/Loader/ComponentLoader";
import { Formik } from "formik";
import toast from "react-hot-toast";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import {
  useSettingDataQuery,
  useUpdateSettingMutation,
} from "store/Actions/setting";

const animatedComponents = makeAnimated();

const SettingForm = () => {
  const { data, isLoading } = useSettingDataQuery();
  const [updateSetting, { isLoading: updateLoading }] =
    useUpdateSettingMutation();

  // Ensure data is available before initializing the form
  const initialValues = {
    unique_visit_time_threshold: data?.data?.unique_visit_time_threshold || 1, // Default to 1 if not available
  };

  if (isLoading) {
    return <ComponentLoader />; // Show loader while fetching data
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        if (!updateLoading) {
          updateSetting(values).then((res) => {
            if (res.error) {
              toast.error(res.error.data.message);
            } else {
              toast.success(res.data?.message);
            }
          });
        }
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        const uniqueVisitTimeThreshold = values?.unique_visit_time_threshold;

        return (
          <form onSubmit={handleSubmit}>
            <div className="absolute top-0 left-0 flex flex-col visible w-full h-auto p-6 break-words bg-white border-0 opacity-100 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border overflow-auto min-w-full md:max-h-85-screen">
              <h5 className="mb-0 font-bold dark:text-white text-xl">
                Superuser Settings
              </h5>
              <div className="flex flex-wrap mt-4 -mx-3 min-h-[50vh]">
                <div className="w-full max-w-full px-3 flex-0 md:w-6/12 relative z-50">
                  <label className="inline-block w-full mb-2 ml-1 font-bold text-slate-700 text-xs">
                    <div className="flex justify-between">
                      <span className="font-semibold tracking-wide text-sm">
                        Set Hours For unique visit threshold
                      </span>
                    </div>
                  </label>

                  <Select
                    value={
                      uniqueVisitTimeThreshold
                        ? {
                            value: uniqueVisitTimeThreshold,
                            label: `${uniqueVisitTimeThreshold < 10 ? "0" : ""}${uniqueVisitTimeThreshold} ${uniqueVisitTimeThreshold < 2 ? "Hour" : "Hours"}`,
                          }
                        : null
                    }
                    options={Array.from({ length: 24 }, (_, index) => ({
                      value: index + 1,
                      label: `${index + 1 < 10 ? "0" : ""}${index + 1} Hours`,
                    }))}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      animatedComponents,
                    }}
                    isMulti={false}
                    onChange={(selectedOption) => {
                      setFieldValue(
                        "unique_visit_time_threshold",
                        selectedOption ? selectedOption.value : null
                      );
                    }}
                    placeholder="Select Hours For unique visit threshold"
                    name="unique_visit_time_threshold"
                  />
                </div>
              </div>
              <div className="flex justify-end gap-3 flex-wrap">
                <div className="flex mt-6">
                  <SubmitButton
                    isLoading={updateLoading}
                    text="Save Changes"
                  />
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default SettingForm;
