import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";


export const ExpandedIcon = ({ item, setSidebarOpen }) => {
  const nav = useNavigate();
  const { pathname } = useLocation();
  const contentSpace = useRef(null);
  const [userRole, setUserRole] = useState(``);
  // Determine if the current pathname matches any child href
  const isAnyChildActive = item.children.some((child) => pathname === child.href);

  const [expanded, setExpanded] = useState(isAnyChildActive);
  const [height, setHeight] = useState(isAnyChildActive ? `${contentSpace.current?.scrollHeight || 0}px` : "0px");
  const [rotate, setRotate] = useState(
    isAnyChildActive ? "transform duration-500 ease -rotate-180" : "transform duration-500 ease rotate-0"
  );

  useEffect(() => {
    // Adjust expanded state and styles when pathname changes
    if (isAnyChildActive) {
      setExpanded(true);
      setHeight(`${contentSpace.current?.scrollHeight || 0}px`);
      setRotate("transform duration-500 ease -rotate-180");
    } else {
      setExpanded(false);
      setHeight("0px");
      setRotate("transform duration-500 ease rotate-0");
    }
    import("utils/functions").then((module) => {
      const loggedUserRole = module.loggedUserRole;
      setUserRole(loggedUserRole());
    });
  }, [pathname, isAnyChildActive]);

  const toggleAccordion = () => {
    setExpanded((prevState) => !prevState);
    setHeight(expanded ? "0px" : `${contentSpace.current?.scrollHeight || 0}px`);
    setRotate(
      expanded
        ? "transform duration-500 ease rotate-0"
        : "transform duration-500 ease -rotate-180"
    );
  };

  return (
    <div className="my-2">
      <div
        onClick={() => toggleAccordion()}
        className={`ease-soft-in-out text-sm py-2.7 active my-0 mx-4 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors cursor-pointer dark:text-white dark:opacity-80 justify-between`}
      >
        <div className="flex items-center">
          <div className="stroke-none shadow-soft-sm bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center fill-current p-1 text-center text-white">
            <item.icon />
          </div>

          <div className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-slate-700">
            {item.name}
          </div>
        </div>
        <div className={`justify-self-end ${rotate} w-5`}>
          <ChevronDownIcon />
        </div>
      </div>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className="overflow-auto transition-max-height duration-500 ease-in-out data_table no_scrollbar"
      >
        <div className="w-full">
          {item.children.map((child) => child?.accessTo?.includes(userRole) && (
            <div
              key={child.name}
              onClick={() => {
                nav(child.href);
                setSidebarOpen && setSidebarOpen(false);
                toggleAccordion();
              }}
              className={`${
                pathname === child.href
                  ? "bg-white shadow-soft-sm z-0"
                  : ""
              } ease-soft-in-out cursor-pointer text-sm py-2 active my-2 mx-4 flex items-center whitespace-nowrap px-6 font-semibold text-slate-700 transition-all w-full dark:text-white dark:opacity-80 hover:bg-white hover:lg:shadow-soft-xl hover:rounded-lg max-w-72`}
            >
              <div className="shadow-soft-sm bg-white mr-2 flex h-8 w-8 items-center justify-center bg-center p-1 text-center">
                <child.icon />
              </div>

              <div className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-slate-700">
                {child.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
