import DataTable from "Components/Table/Index"
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useBrokerVesselsListQuery } from "store/Actions/brokerAnalytics";

const VesselList = () => {
  
  const [filter, setFilter] = useState({
    sort: "name",
    order: "asc",
    perPage: 50,
    page: 1,
    searchField: ``,
    searchValue: ``,
  });
  const { data, isLoading } = useBrokerVesselsListQuery(filter);
  const columns = useMemo(
    () => [
      {
        header: "Vessel Image",
        accessor: "vessel_image_url",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="flex">
              <img
                className="ml-4 w-10/12 md:w-8/12 lg:w:4/12"
                src={value}
                alt="..."
              />
            </div>
          );
        },
      },
      {
        header: "vessel name ",
        accessor: "vesselname",
      },
      {
        header: "brokerage name",
        accessor: "brokerage_name",
      },
      {
        header: "vessel id",
        accessor: "vessel_id",
      },
      {
        header: "manufacturer",
        accessor: "manufacturer",
      },
      {
        header: "Vessel   Length",
        accessor: "display_length_feet",
      },

      {
        header: " year",
        accessor: "year",
      },
      {
        header: "view Details",
        accessor: "id",
        Cell: ({ cell: { row } }) => {
          return (
            <Link
              to={`/vessel-details/view/${row.original.vessel_id}`}
              className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit"
            >
           <button>view details</button>
            </Link>
          );
        },
      },
      // {
      //   header: "Visitors",
      //   accessor: "visitor_counts",
      // },
      // {
      //   header: "Guests",
      //   accessor: "guest_counts",
      // },
      // {
      //   header: "Date",
      //   accessor: "updated_at",
      //   Cell: ({ cell: { value } }) => {
      //     return (
      //       <span className={``}>
      //         {moment(value).format("MM-DD-YYYY")}
      //       </span>
      //     );
      //   },
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <div className="flex flex-wrap my-3 -mx-3">
    <div className="w-full max-w-full px-3 flex-0">
      <div className="relative flex flex-col min-w-0 p-2 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
                {!isLoading ? (
                  <DataTable
                    ListQuery={{ data: data?.data?.vessel, isLoading }}
                    filterProps={{ filter, setFilter }}
                    columns={columns}
                    sortingField={[]}
                    searchFields={[]}
                  />
                ) : (
                  <></>
                )}
          </div>
          </div>
        </div>
  )
}

export default VesselList