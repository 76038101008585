// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating authAction by enhancing and injecting endpoints using serverApi
const authAction = serverApi
  .enhanceEndpoints({ tagTypes: ["Superuser","Admin", "OfficeAdmin", "Broker"], })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Mutation endpoint for switching to a brokerage
      switchToAnyUser: builder.mutation({
        query(id) {
          return {
            url: `/switch-to-user/${id}`,
            method: "POST",
          };
        },
        // Invalidates broker tags after creating a new broker
        invalidatesTags: ["Superuser","Admin", "OfficeAdmin", "Broker"],
      }),

      // Mutation endpoint for switching back to admin role
      switchToAdmin: builder.mutation({
        query(id) {
          return {
            url: `back-to-admin/${id}`,
            method: "POST",
          };
        },
        // Invalidates broker tags after creating a new broker
        invalidatesTags: ["Superuser","Admin", "OfficeAdmin", "Broker"],
      }),
    }),
  });

// Destructuring authAction to get individual hooks for components
export const { useSwitchToAnyUserMutation, useSwitchToAdminMutation } =
  authAction;
