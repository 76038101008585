import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useBotAnalyticsQuery } from "store/Actions/adminAction";
import Skeleton from "react-loading-skeleton";

const BotChart = () => {
  const { data, isLoading } = useBotAnalyticsQuery();
  const [botChartData, setBotChartData] = useState([]);
  const [otherChartData, setOtherChartData] = useState([]);

  useEffect(() => {
    if (data?.data) {
      // Safely access nested properties
      const botsData = data.data?.bots || [];
      const othersData = data.data?.others || [];

      const botFormattedData = botsData.map((item) => ({
        name: item.browser_name || "Unknown",
        y: item.counter || 0,
      }));

      const otherFormattedData = othersData.map((item) => ({
        name: item.browser_name || "Unknown",
        y: item.counter || 0,
      }));

      setBotChartData(botFormattedData);
      setOtherChartData(otherFormattedData);
    }
  }, [data]);

  const optionsForBots = {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    plotOptions: {
      pie: {
        innerSize: "50%", // Makes it a donut chart
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.y}",
        },
      },
    },
    series: [
      {
        name: "Visits",
        colorByPoint: true,
        data: botChartData,
      },
    ],
  };

  const optionsForOthers = {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    plotOptions: {
      pie: {
        innerSize: "50%", // Makes it a donut chart
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.y}",
        },
      },
    },
    series: [
      {
        name: "Visits",
        colorByPoint: true,
        data: otherChartData,
      },
    ],
  };

  return (
    <div className="max-h-8/10">
      <div className="flex flex-wrap mt-6 -mx-3 ">
      <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-6/12 lg:flex-none">
          <h3 className="text-xl font-semibold mb-3">Organic Traffic</h3>
          {isLoading ? (
            <div className="flex justify-center ">
              <Skeleton className="rounded-full" count={1} height={360} width={360} />
            </div>
          ) : (
            <HighchartsReact highcharts={Highcharts} options={optionsForOthers} />)}
        </div>
        <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-6/12 lg:flex-none">
          <h3 className="text-xl font-semibold mb-3">Bots</h3>
          {isLoading ? (
            <div className="flex justify-center ">
              <Skeleton className="rounded-full" count={1} height={360} width={360} />
            </div>
          ) : (
            <HighchartsReact highcharts={Highcharts} options={optionsForBots} />)}
        </div>
      </div>
    </div>
  );
};

export default BotChart;
