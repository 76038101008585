import { Suspense, lazy } from "react";
import { Tab, TabGroup, TabList, TabPanel } from "@headlessui/react";

const TagWiseInquiries = (props) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const { role, tags, priority = false } = props;
  let MiniTableComponent;
  if (role === 'Superuser') {
    MiniTableComponent = lazy(() => import('pages/BoatInquiry/ForAdmin/MiniTable'));
  } else if (role === 'Admin' || role === 'OfficeAdmin') {
    MiniTableComponent = lazy(() => import('pages/BoatInquiry/ForBrokerage/MiniTable'));
  } else if (role === 'Broker') {
    MiniTableComponent = lazy(() => import('pages/BoatInquiry/ForBroker/MiniTable'));
  } else {
    return <div>&nbsp;</div>; // Handle invalid roles
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <>
        {priority === false ? (
          <TabGroup>
            <TabList className="flex flex-wrap justify-left space-x-2 rounded-xl p-2">
              {tags.map((tag,) => (
                <Tab
                  key={tag.id}
                  style={{
                    borderColor: tag?.color,
                    color: tag?.font_color,
                    borderWidth: `1px`
                  }}
                  className={({ selected }) =>
                    classNames(
                      "w-auto rounded-lg p-4 text-sm font-medium leading-5",
                      "inline-block px-8 py-2 m-0 text-sm font-bold text-center transition-all cursor-pointer",
                      selected
                        ? "shadow-md"
                        : "hover:bg-gray-100 hover:text-gray-800 border-1 border-black"
                    )
                  }
                >
                  {tag.tag}
                </Tab>
              ))}
            </TabList>
            {tags.map((tag,) => (
              <TabPanel
                className={`p-1`}
                key={tag.id}>
                <MiniTableComponent
                  tag={tag}
                  limit={10}
                  sortField={priority && priority === true ? `created_at` : `updated_at`}
                  sortOrder={`desc`}
                  shouldSkip={tag.tag_count < 1}
                  hideColumns={``}
                />
              </TabPanel>
            ))}
          </TabGroup>
        ) : (
        <MiniTableComponent
          tag={``}
          limit={10}
          sortField={priority && priority === true ? `created_at` : `updated_at`}
          sortOrder={`desc`}
          shouldSkip={false}
          hideColumns={``}
        />)}

      </>

    </Suspense>
  );
};

export default TagWiseInquiries;
