
import BrowserImg from "Components/Browser/BrowserImg";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useAdminVisitorDetailsQuery } from "store/Actions/adminAnalytics";
import AccountInquries from "./AccountInquries";
import AccountVisits from "./AccountVisits";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { Tab, TabGroup, TabList, TabPanel } from "@headlessui/react";


const AccountDetails = () => {
    const { deviceId } = useParams();
    const [visitorData, setVisitorData] = useState({});
    const { isFetching, data } = useAdminVisitorDetailsQuery({ deviceId });

    useEffect(() => {
        setVisitorData(() => ({ ...data?.data?.device, ...data?.data?.visitor }));
    }, [data]);

    const openMap = () => {
        if (visitorData?.longitude && visitorData?.latitude) {
            window.open("https://maps.google.com?q=" + visitorData?.latitude + "," + visitorData?.longitude);
        } else {
            toast.error("Can't Find Location");
        }
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    return (
        <div className="flex flex-wrap my-3 -mx-3">
            <div className="w-full max-w-full px-3 flex-0">
                <div className="relative flex flex-col min-w-0 p-2 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">

                    {isFetching ? (
                        <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                            <Skeleton count={10} />
                        </div>
                    ) : (
                        <>
                            <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                                <div className="lg:flex justify-between">
                                    <div>
                                        <h5 className="mb-5 font-bold text-3xl text-blue-800 dark:text-white">
                                            Account Details
                                        </h5>
                                        <div><span>Id: </span><span className="font-semibold">{visitorData?.id}</span></div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-4 bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:via-white dark:to-transparent" />

                            <div className="flex justify-between px-6 my-2">
                                <div className="w-1/3">
                                    <div><span>Name: </span><span className="font-semibold">{visitorData?.name || "Visitor"}</span></div>
                                    <div><span>City: </span><span className="font-semibold">{visitorData?.city?.name}</span></div>
                                    <div className="flex"><span>Mobile: </span><span className="font-semibold">{visitorData?.phone && <a href={`tel:${visitorData?.phone}`} className="text-xs py-1.2 px-2.6 rounded-1.8 bg-slate-200 text-slate-700">{visitorData?.phone}</a>}</span></div>
                                </div>
                                <div className="w-1/3">
                                    <div className="flex"><span>Email: </span><span className="font-semibold">{visitorData?.email && <a href={`mailto:${visitorData?.email}`} className="text-xs py-1.2 px-2.6 rounded-1.8 bg-slate-200 text-slate-700">{visitorData?.email}</a>}</span></div>
                                    <div><span>Region: </span><span className="font-semibold">{visitorData?.region?.name}</span></div>
                                </div>
                                <div className="w-1/3">
                                    <div><span>IP: </span><span className="font-semibold">{visitorData?.ip}</span></div>
                                    <div><span>Country: </span><span className="font-semibold">{visitorData?.country?.name}</span></div>
                                </div>
                            </div>

                            <h5 className="mb-2 font-bold text-xl text-blue-800 dark:text-white px-6">
                                Access History
                            </h5>
                            <hr className="my-4 bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:via-white dark:to-transparent" />

                            <div className="flex justify-between px-6 my-2">
                                <div className="w-1/3">
                                    <div className="flex items-center gap-1"><span>Browser: </span><BrowserImg browser={visitorData?.browser?.name} /> <span className="font-semibold">{visitorData?.browser?.name}</span></div>
                                    <div><span>Location: </span><button className="px-4 py-2 text-xs rounded-lg bg-blue-600 text-white" onClick={openMap}>Open Map</button></div>
                                </div>
                                <div className="w-1/3">
                                    <div><span>Version: </span><span className="font-semibold">{visitorData?.browser_version?.name}</span></div>
                                </div>
                                <div className="w-1/3">
                                    <div><span>OS: </span><span className="font-semibold">{visitorData?.os?.name}</span></div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="w-full p-6 mx-auto">
                <TabGroup>
                    <TabList className="flex flex-wrap justify-left space-x-2 rounded-xl p-2">
                    <Tab
                            key="Visits"
                            className={({ selected }) =>
                                classNames(
                                    "w-auto rounded-lg p-4 text-sm font-medium leading-5",
                                    "inline-block px-8 py-2 m-0 text-sm font-bold text-center transition-all cursor-pointer",
                                    selected
                                        ? "text-white bg-gradient-to-tl from-blue-600 to-blue-800 shadow-md"
                                        : "hover:bg-gray-100 hover:text-gray-800 border-1 border-gray"
                                )
                            }
                        >Visits</Tab>
                        <Tab
                            key="Inquiries"
                            className={({ selected }) =>
                                classNames(
                                    "w-auto rounded-lg p-4 text-sm font-medium leading-5",
                                    "inline-block px-8 py-2 m-0 text-sm font-bold text-center transition-all cursor-pointer",
                                    selected
                                        ? "text-white bg-gradient-to-tl from-blue-600 to-blue-800 shadow-md"
                                        : "hover:bg-gray-100 hover:text-gray-800 border-1 border-gray"
                                )
                            }
                        >Inquiries</Tab>

                    </TabList>
                    <TabPanel key="Visits">
                        <AccountVisits />
                    </TabPanel>
                    <TabPanel key="Inquiries">
                        <AccountInquries />
                    </TabPanel>

                </TabGroup>
            </div>
        </div>
    );
};

export default AccountDetails;
