const CustomInput = ({
  name,
  label,
  values,
  type,
  placeholder,
  handleBlur,
  handleChange,
  errors,
  touched,
  isRequire,
  isDisabled,
  customStyle,
  numberConfig = null
}) => {
  return (
    <>
      {label && (
        <label className="block w-full mb-2 ml-1 font-bold text-slate-700 text-xs">
          <div className="flex justify-between">
            <span className="font-semibold tracking-wide text-sm">
              {label}{" "}
              {isRequire ? <span className="text-red-500 ">*</span> : <></>}
            </span>
            <span className="text-sm font-medium text-red-500">
              {errors[name] && touched[name] && errors[name]}
            </span>
          </div>
        </label>
      )}
      <input
        type={type || "text"}
        placeholder={placeholder || ""}
        id={name || ""}
        name={name || ""}
        value={values[name] || ""}
        onBlur={handleBlur}
        step={numberConfig && numberConfig.step || ``}
        onChange={handleChange}
        disabled={isDisabled || false}
        className={`focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-cyan-300 focus:outline-none ${
          customStyle || ""
        }`}
      />
    </>
  );
};

export default CustomInput;
