import { Suspense, lazy, useEffect, useState } from "react";

const BoatInquiry = () => {
  const [userRole, setUserRole] = useState(``);
  useEffect(() => {

    import("utils/functions").then((module) => {
      const loggedUserRole = module.loggedUserRole;
      setUserRole(loggedUserRole());
    });
  }, [userRole])

  let TableComponent;

  if (userRole === 'Superuser') {
    TableComponent = lazy(() => import('./ForAdmin/Table'));
  } else if (userRole === 'Admin' || userRole === 'OfficeAdmin') {
    TableComponent = lazy(() => import('./ForBrokerage/Table'));
  } else if (userRole === 'Broker') {
    TableComponent = lazy(() => import('./ForBroker/Table'));
  } else if (userRole === 'Guest') {
    TableComponent = lazy(() => import('./ForGuest/Table'));
  } else {
    return <div>Access Denied</div>; // Handle invalid roles
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TableComponent
        hideColumns={['Tag', 'Notes']} />
    </Suspense>
  );
};

export default BoatInquiry;
