import { useEffect, useState } from "react";
import BrokerGraph from "./BrokerGraph";
import VesselGraph from "./VesselWiseGraph";
import { humanReadableRange } from "utils/functions";

function BrokerAnalytics({ startsAtDate, endsAtDate }) {
  const [graphInfo, setGraphInfo] = useState({
    currentGraph: "broker",
    broker: null,
  });
  const [filter, setFilter] = useState({
    sort: "count_vessel_id_wise",
    order: "desc",
    start_date: startsAtDate,
    end_date: endsAtDate,

  });
  useEffect(() => {

    setFilter(() => {
      return {
        ...filter,
        start_date: startsAtDate,
        end_date: endsAtDate,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-4/12 lg:flex-none">
      <div className="relative z-20 flex flex-col min-w-0 break-words bg-white border-0 border-solid dark:bg-gray-950 border-black-125 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border p-2">
        <div className="my-auto flex ml-auto lg:mt-0 ">
          {graphInfo.currentGraph !== "broker" && (
            <div>
              {" "}
              <span
                className="text-blue-800 hover:underline cursor-pointer"
                onClick={() =>
                  setGraphInfo((graphInfo) => ({
                    ...graphInfo,
                    currentGraph: "broker",
                    broker: null,
                  }))
                }
              >
                Broker
              </span>{" "}

              {graphInfo?.broker && (
                <span
                  className="text-blue-800 hover:underline cursor-pointer hover:text-blue-900"
                  dangerouslySetInnerHTML={{
                    __html: `/ ${graphInfo?.broker}`,
                  }}
                ></span>
              )}
            </div>
          )}

        </div>
        <div className="overflow-y-auto">
          {graphInfo.currentGraph === "broker" ? (
            <BrokerGraph
              graphState={{ graphInfo, setGraphInfo }}
              filter={filter}
            />
          ) : (
            <VesselGraph
              graphState={{ graphInfo, setGraphInfo }}
              filter={filter}
            />
          )}

        </div>
        <div className="flex justify-center  text-sm font-semibold">
          {" "}
          {filter && <span>{ humanReadableRange(`${(filter.start_date.split('_')).join(` `)} To ${(filter.end_date.split('_')).join(` `)}`)}</span>}
        </div>
      </div>
    </div>
  );
}
export default BrokerAnalytics;
