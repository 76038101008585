import ActivityLog from "pages/Activity/Index";
import BoatInquiry from "pages/BoatInquiry/Index";
import BrandsAnalytics from "pages/BrandsAnalytics/Index";
import ViewBroker from "pages/Broker/ViewBroker";
import BrokerList from "pages/Broker/Index";

import CategoriesAnalytics from "pages/CategoriesAnalytics/Index";
import AccountDetails from "pages/DetailsPage/AccountDetails";
import GuestAnalytics from "pages/GuestAnalytics/Index";
import Login from "pages/Login/Index";
import Profile from "pages/Profile/Index";
import ResetPassword from "pages/ResetPassword/Index";
import Roles from "pages/Role/Index";

import SearchHistory from "pages/SearchHistory/Index";
import Setting from "pages/Setting/Index";
import BrokerageAnalytics from "pages/BrokerageAnalyticsGraph/Index";
import AdminVesselsAnalytics from "pages/VesselsAnalytics/Index";
import VesselDetails from "pages/DetailsPage/VesselDetails";
import VisitorsAnalytics from "pages/VisitorsAnalytics/Index";
import VisitorsDetailsAnalytics from "pages/VisitorsAnalytics/VisitorsDetailsAnalytics";
import ForgetPassword from "pages/forgetPassword/Index";
import { Navigate } from "react-router-dom";
import Dashboard from "pages/Dashboard/Index";
// import InquiryEvents from "pages/InquiryEvents/Index";
// import InquiryEventForm from "pages/InquiryEvents/Form";

export const routesData = [
  // Authenticate require as per need

  {
    name: "Dashboard",
    path: "/",
    element: <Dashboard />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Roles",
    path: "/roles",
    element: <Roles />,
    accessTo: ["Superuser"],
  },
  // {
  //   name: "Inquiry Events",
  //   path: "/inquiry-events",
  //   element: <InquiryEvents />,
  //   accessTo: ["Superuser"],
  // },
  // {
  //   name: "Edit Inquiry Event",
  //   path: "/inquiry-events/edit/:id",
  //   element: <InquiryEventForm />,
  //   accessTo: ["Superuser"],
  // },
  // {
  //   name: "Create Inquiry Event",
  //   path: "/inquiry-events/create",
  //   element: <InquiryEventForm />,
  //   accessTo: ["Superuser"],
  // },
  {
    name: "Users List",
    path: "/broker",
    element: <BrokerList />,
    accessTo: ["Superuser", "Admin", "OfficeAdmin"],
  },
  {
    name: "View User",
    path: "/broker/view/:id",
    element: <ViewBroker />,
    accessTo: ["Superuser", "Admin", "OfficeAdmin"],
  },
  {
    name: "My Profile",
    path: "/profile",
    element: <Navigate to="/my-profile" />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin", "Guest"],
  },
  {
    name: "My Profile",
    path: "/my-profile",
    element: <Profile />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin", "Guest"],
  },

  {
    name: "Activity Log",
    path: "/activity",
    element: <ActivityLog />,
    accessTo: ["Superuser"],
  },
  {
    name: "My Inquiries",
    path: "/my-inquiries",
    element: <BoatInquiry />,
    accessTo: ["Guest"],
  },
  {
    name: "Setting",
    path: "/setting",
    element: <Setting />,
    accessTo: ["Superuser"],
  },
  {
    name: "Boat Inquiry",
    path: "/boat-inquiry",
    element: <BoatInquiry />,
    accessTo: ["Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Categories Analytics",
    path: "/categories-analytics",
    element: <CategoriesAnalytics />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Brands Analytics",
    path: "/brands-analytics",
    element: <BrandsAnalytics />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Search History",
    path: "/search-history",
    element: <SearchHistory />,
    accessTo: ["Superuser"],
  }, {
    name: "Brokerage Analytics",
    path: "/brokerage-analytics",
    element: <BrokerageAnalytics />,
    accessTo: ["Superuser"],
  },
  {
    name: "Vessels Analytics",
    path: "/vessels-analytics",
    element: <AdminVesselsAnalytics />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Vessel Details",
    path: "/vessel-details/view/:vesselId",
    element: <VesselDetails />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin", "Guest"],
  },
  {
    name: "Visitors Analytics",
    path: "/visitors-analytics",
    element: <VisitorsAnalytics />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Visitor Data",
    path: "/visitors-analytics/view/:deviceId",
    element: <VisitorsDetailsAnalytics />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Guest Analytics",
    path: "/guest-analytics",
    element: <GuestAnalytics />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Vessel Inquiries",
    path: "/vessel-inquiries",
    element: <BoatInquiry />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Account Details",
    path: "/account-details/view/:deviceId",
    element: <AccountDetails />,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
  },
  //   ... as per need
];

export const openRoute = [
  {
    name: "Login",
    path: "/login",
    element: <Login />,
  },
  {
    name: "Forget Password",
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    name: "Reset Password",
    path: "/reset-password/:resetToken",
    element: <ResetPassword />,
  },
];
