
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const customBaseQuery = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    // eslint-disable-next-line no-undef
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      if (localStorage.getItem("token")) {
        headers.set("Authorization", "Bearer " + localStorage.getItem("token"));
      }
      return headers;
    },
  })(args, api, extraOptions);

  // Do something with response status code

  if (baseResult.meta?.response?.status == "401") {
    if (localStorage.getItem("token")) {
      localStorage.clear();
      // eslint-disable-next-line no-undef
      window.location.href = process.env.REACT_APP_FRONT_URL;
    }
  }
  return baseResult;
};

export const serverApi = createApi({
  baseQuery: customBaseQuery,
  reducerPath: "serverApi",
  refetchOnFocus: false,
  refetchOnReconnect: true,
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});
