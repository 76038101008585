import ErrorPage from "pages/ErrorPage/Index";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";


const ProtectedLogin = ({ accessTo = [], children, name = "" }) => {
  const [userRole, setUserRole] = useState(``);

  useEffect(() => {
    document.title = name ? `Yachtr | ${name}` : "Yachtr";
    import("utils/functions").then((module) => {
      const loggedUserRole = module.loggedUserRole;
      setUserRole(loggedUserRole());
    });
  }, [name]);

  const token = localStorage.getItem("token");
 
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (!accessTo.includes(userRole)) {
    return <ErrorPage />;
  }

  return children;
};

export default ProtectedLogin;
