import { Suspense, lazy, useEffect, useState } from "react";
import { useParams } from "react-router-dom";


const VesselsInquires = ({ filterProps, totalCount = 0 }) => {
  const { vesselId } = useParams();
  const [userRole, setUserRole] = useState(``);

  const [filter, setFilter] = useState({
    perPage: 50,
    page: 1,
    start_date: filterProps?.filter?.start_date,
    end_date: filterProps?.filter?.end_date,
    vessel_id: vesselId
  });

  useEffect(() => {
    import("utils/functions").then((module) => {
      const loggedUserRole = module.loggedUserRole;
      setUserRole(loggedUserRole());
    });
  }, [userRole])

  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      end_date: filterProps?.filter?.end_date,
      start_date: filterProps?.filter?.start_date,
    }));
  }, [filterProps.filter.end_date, filterProps?.filter?.start_date]);

  let TableComponent;
  if (userRole === 'Superuser') {
    TableComponent = lazy(() => import('../BoatInquiry/ForAdmin/Table'));
  } else if (userRole === 'Admin' || userRole === 'OfficeAdmin') {
    TableComponent = lazy(() => import('../BoatInquiry/ForBrokerage/Table'));
  } else if (userRole === 'Broker') {
    TableComponent = lazy(() => import('../BoatInquiry/ForBroker/Table'));
  } else if (userRole === 'Guest') {
    TableComponent = lazy(() => import('../BoatInquiry/ForGuest/Table'));
  } else {
    return <div>Access Denied</div>; // Handle invalid roles
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TableComponent
        filterProps={{ filter: filter, setFilter: setFilter }}
        hideColumns={['Vessel','Tag','Notes']}
        shouldSkip={totalCount === 0}
      />
    </Suspense>
  );
};

export default VesselsInquires;
