import HighChart from "Components/Chart/HighChart";
import SkeletonLoader from "Components/Loader/SkeletonLoader";
import { useEffect, useState } from "react";

function ColumnChart({ data, isLoading }) {
    const [chartOptions, setChartOptions] = useState(null);

    useEffect(() => {

        if (data) {
            const categories = data.map((item) => item.tag);
            const dataSeries = data.map((item) => ({
                y: item.tag_count,
                color: item.color, // Use the color provided in the data
            }));
            const options = {
                chart: {
                    renderTo: "chartContainer",
                    type: "column",
                    showAxes: true,
                    marginRight: 0,
                    spacingRight: 0,
                    plotAreaHeight: 300,
                },
                title: {
                    text: `Inquiries`,
                },
                xAxis: {
                    allowDecimals: false,
                    min: 0,
                    max: categories.length > 50 ? 50 : null,
                    categories: [...categories],
                    tickLength: 0,
                },
                yAxis: {
                    allowDecimals: false,
                    showFull: false,
                    title: {
                        text: "Counts",
                    },
                    stackLabels: {
                        enabled: false,
                    },
                },
                tooltip: {
                    enabled: true,
                    formatter: function () {
                        //eslint-disable-next-line no-console
                        console.log(this?.key);
                        let tooltipContent = `<div style="height:130px; text-transform: capitalize;">${this?.key} <b>${this?.y}</b></div> `;
                        return tooltipContent;
                    },
                },
                legend: {
                    enabled: false,
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        turboThreshold: 5000, //set it to a larger threshold, it is by default to 1000
                    },
                    column: {
                        stacking: "normal",
                    },
                },
                series: [
                    {
                        name: "Tags",
                        data: dataSeries, // Data series with colors
                    },
                ],
            };

            setChartOptions(options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading]);

    return (

        <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid dark:bg-gray-950 border-black-125 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border p-2 ">
            {isLoading ? (
                <SkeletonLoader />
            ) : (
                <HighChart chartOptions={chartOptions} />
            )}
            <div className="flex justify-center text-sm font-semibold">
                {" "}
            </div>
        </div>

    );
}

export default ColumnChart;
