import SubmitButton from "Components/Butttons/SubmitButton";
import CustomInput from "Components/Input/Input";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useUpdateLoginDetailsMutation,
  useUserProfileQuery,
} from "store/Actions/authAction";
import * as Yup from "yup";

// Creating schema
const schema = Yup.object({});

const ProfileLoginDetails = (props) => {
  const {userRole} = props;
  const [currentUser, setCurrentUser] = useState({});

  const { data } = useUserProfileQuery();
  const [updateLoginDetails, { isLoading }] = useUpdateLoginDetailsMutation();

  useEffect(() => {
    if (data?.data) {
      setCurrentUser(data?.data.user);
    }
  }, [data]);

  return (
    <div className=" flex flex-col visible w-full h-auto min-w-0 p-4 break-words bg-white border-0 opacity-100 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border lg:w-8/12 m-4  ">
      <Formik
        enableReinitialize
        initialValues={{
          email: currentUser?.email || "",
          username: currentUser?.username || "",
        }}
        onSubmit={async (values) => {
          !isLoading &&
            updateLoginDetails(values).then((res) => {
              if (res.error) {
                toast.error(res.error.data.message);
              } else {
                toast.success(res.data?.message);
              }
            });
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full  px-3 flex-0 ">
                      <CustomInput
                        name="email"
                        values={values}
                        type="text"
                        label="email"
                        placeholder="Email Address"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        isRequire={true}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full  px-3 flex-0 ">
                      <CustomInput
                        name="username"
                        values={values}
                        type="text"
                        label="Username"
                        placeholder="username"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        isRequire={true}
                      />
                    </div>
                  </div>

                  <div className="flex mt-6">
                    {userRole =='Guest' ? (<SubmitButton isLoading={isLoading} text="Update" />) : null }                    
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProfileLoginDetails;
