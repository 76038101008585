import moment from "moment";

export const loggedUserRole = () => {
  if (localStorage.getItem("userInfo")) {
    return JSON.parse(localStorage.getItem("userInfo")).role;
  }
};

export const loggedUserCode = () => {
  if (localStorage.getItem("userInfo")) {
    return JSON.parse(localStorage.getItem("userInfo")).user_code;
  }
};

export const loggedBackId = () => {
  if (localStorage.getItem("userInfo")) {
    return JSON.parse(localStorage.getItem("userInfo")).user_id;
  }
};

export const remainingWords = (inputString, totalWordCount) => {
  const word = totalWordCount - inputString.split("").length;
  if (word > 0) {
    return { val: true, text: ` ${word}  characters left` };
  } else {
    return { val: false, text: `last ${Math.abs(word)} will be skipped` };
  }
};
export const humanReadableRange = (dateRange) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
  return dateRange.replace(/(\d{2}) (\d{2}) (\d{4})/g, (_, month, day, year) => {
      return `${parseInt(day)} ${months[parseInt(month) - 1]}, ${year}`;
  });
};

export const currentYear = () => {
  return moment().year();
};
export const currentMonth = () => {
  // return `04`;
  return moment().month() + 1;
};
export const currentDate = () => {
  return moment().date();
};

export const generateDateRanges = () => {
  let dateRanges = [];
  const currentDate = new Date();

  function formatDate(date, asText = false) {
    let monthFormatted;
    if (asText) {
      monthFormatted = date.toLocaleString('default', { month: 'short' }) + ', ' + date.getFullYear();
    } else {
      monthFormatted = String(date.getMonth() + 1).padStart(2, '0') + '_' + String(date.getDate()).padStart(2, '0') + '_' + date.getFullYear();
    }
    // Format the date as 'mm_dd_yyyy'
    return (
      monthFormatted
    );
  }

  // Generate Last 7 Days
  const last7Days = new Date(currentDate);
  last7Days.setDate(currentDate.getDate() - 7);
  dateRanges.push({
    start_date: formatDate(last7Days),
    end_date: formatDate(currentDate),
    text: 'Last 7 Days',
    index : 0,
  });

  // Generate Last 30 Days
  const last30Days = new Date(currentDate);
  last30Days.setDate(currentDate.getDate() - 30);
  dateRanges.push({
    start_date: formatDate(last30Days),
    end_date: formatDate(currentDate),
    text: 'Last 30 Days',
    index : 1,
  });

  for (let i = 1,index = 2; i <= 4; i++,index++) {
    const previousMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    const previousMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() - i + 1, 0);
    dateRanges.push({
      start_date: formatDate(previousMonthStart),
      end_date: formatDate(previousMonthEnd),
      text: formatDate(previousMonthStart, true),
      index : index,
    });
  }

  return dateRanges;
}

