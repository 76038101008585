// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating brokerManagement by enhancing and injecting endpoints using serverApi
const brokerManagement = serverApi
  .enhanceEndpoints({ tagTypes: ["Superuser", "Admin", "OfficeAdmin", "Broker"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokers
      brokerList: builder.query({
        query(filter) {
          // Encode the object's data into URL parameters
          const queryString = new URLSearchParams(filter?.column_search).toString();
          return {
            url: `broker?page=${filter.page || 1}&per_page=${filter?.perPage || 50
              }&sort_field=${filter?.sort || "name"}&sort_order=${filter?.order || "asc"
              }&${queryString && queryString}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        providesTags: ["Broker"],
      }),

      // Mutation endpoint for retrieving details of a specific broker
      brokerDetails: builder.mutation({
        query(id) {
          return {
            url: `broker/${id}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker details data
        providesTags: ["BrokerDetail"],
      }),
      brokerProfile: builder.query({
        query(val) {
        //// eslint-disable-next-line no-console
        //console.log(val);
        const queryString = new URLSearchParams(val?.filter?.column_search).toString();
          return {
            url: `broker-details/${val?.id}?page=${val?.filter.page || 1
              }&${queryString && queryString
              }&per_page=${val?.filter?.perPage || 50}&sort_field=${val?.filter?.sort || ""
              }&sort_order=${val?.filter?.order}&search_value=${val?.filter.searchValue || ``
              }&search_field=${val?.filter.searchField || ``}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker details data
        providesTags: ["BrokerProfile"],
      }),
    }),
  });

// Destructuring brokerManagement to get individual hooks for components
export const {
  useBrokerListQuery,
  useBrokerDetailsMutation,
  useBrokerProfileQuery,
} = brokerManagement;
