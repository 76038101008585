import { Suspense, lazy, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const AccountInquries = () => {
  const { deviceId } = useParams();
  const [searchParams] = useSearchParams();
  const [userRole, setUserRole] = useState(``);

  const [filter, setFilter] = useState({
    perPage: 50,
    page: 1,
    start_date: searchParams.get("from") ? searchParams.get("from") : null,
    end_date: searchParams.get("to") ? searchParams.get("to") : null,
    device_id: deviceId
  });

  useEffect(() => {
    import("utils/functions").then((module) => {
      const loggedUserRole = module.loggedUserRole;
      setUserRole(loggedUserRole());
    });
  }, [userRole])

  let TableComponent;
  if (userRole === 'Superuser') {
    TableComponent = lazy(() => import('../BoatInquiry/ForAdmin/Table'));
  } else if (userRole === 'Admin' || userRole === 'OfficeAdmin') {
    TableComponent = lazy(() => import('../BoatInquiry/ForBrokerage/Table'));
  } else if (userRole === 'Broker') {
    TableComponent = lazy(() => import('../BoatInquiry/ForBroker/Table'));
  } else {
    return <div>Access Denied</div>; // Handle invalid roles
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TableComponent
        filterProps={{ filter: filter, setFilter: setFilter }}
        hideColumns={['Contact','Tag','Notes', 'Action']}
      />
    </Suspense>
  );
};


export default AccountInquries