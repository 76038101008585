import {

  ChartBarSquareIcon,
  Cog8ToothIcon,
  DocumentChartBarIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  HomeIcon,
  ListBulletIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  TagIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export const sidebarList = [
  {
    name: "Dashboard",
    href: "/",
    icon: HomeIcon,
    current: true,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Boat Inquiries",
    href: "/boat-inquiry",
    icon: DocumentTextIcon,
    current: false,
    accessTo: ["Admin", "Broker", "OfficeAdmin"],
  },
  {
    name: "Vessel Inquiries",
    href: "/vessel-inquiries",
    icon: DocumentTextIcon,
    current: false,
    accessTo: ["Superuser"],
  },
  {
    name: "Analytics",
    icon: PresentationChartBarIcon,
    current: false,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
    children: [
      {
        name: "Categories",
        href: "/categories-analytics",
        icon: PresentationChartBarIcon,
        current: false,
        accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
      },
      {
        name: "Brands",
        href: "/brands-analytics",
        icon: PresentationChartLineIcon,
        current: false,
        accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
      },
      {
        name: "Vessels",
        href: "/vessels-analytics",
        icon: DocumentTextIcon,
        current: false,
        accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
      },
      {
        name: "Visitors",
        href: "/visitors-analytics",
        icon: DocumentTextIcon,
        current: false,
        accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
      },
      {
        name: "Guest",
        href: "/guest-analytics",
        icon: DocumentTextIcon,
        current: false,
        accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin"],
      },
      {
        name: "Brokerage Analytics",
        href: "/brokerage-analytics",
        icon: ChartBarSquareIcon,
        current: false,
        accessTo: ["Superuser"],
      },
    ],
  },
  {
    name: "Search History",
    href: "/search-history",
    icon: DocumentMagnifyingGlassIcon,
    current: false,
    accessTo: ["Superuser"],
  },
  {
    name: "Users",
    icon: UsersIcon,
    current: false,
    accessTo: ["Superuser", "Admin", "OfficeAdmin"],
    children: [
      {
        name: "List All",
        href: "/broker",
        icon: ListBulletIcon,
        current: false,
        accessTo: ["Superuser", "Admin", "OfficeAdmin"],
      },
    ],
  },
  {
    name: "My Inquiries",
    href: "/my-inquiries",
    icon: DocumentTextIcon,
    current: false,
    accessTo: ["Guest"],
  },
  {
    name: "My Profile",
    href: "/my-profile",
    icon: UserIcon,
    current: true,
    accessTo: ["Superuser", "Admin", "Broker", "OfficeAdmin", "Guest"],
  },
  {
    name: "Activity Log",
    href: "/activity",
    icon: DocumentChartBarIcon,
    current: false,
    accessTo: ["Superuser"],
  },
  {
    name: "Settings",
    icon: Cog8ToothIcon,
    current: false,
    accessTo: ["Superuser"],
    children: [
      {
        name: "Roles",
        href: "/roles",
        icon: TagIcon,
        current: false,
        accessTo: ["Superuser"],
      },
      // {
      //   name: "Inquiry Events",
      //   href: "/inquiry-events",
      //   icon: TagIcon,
      //   current: false,
      //   accessTo: ["Superuser"],
      // },
      {
        name: "Global Setting",
        href: "/setting",
        icon: Cog8ToothIcon,
        current: false,
        accessTo: ["Superuser"],
      },
    ],
  },
];
