import { useEffect, useState } from "react";

import { Tab, TabGroup, TabList, TabPanel } from "@headlessui/react";
import { generateDateRanges } from "utils/functions";
import TabItem from "./TabItem";
import BotChart from "./BotChart";
import TagStatistcs from "./TagStatistcs/Index";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const dateRanges = generateDateRanges();


const Dashboard = () => {
  const [userRole, setUserRole] = useState(``);
  useEffect(() => {
    import("utils/functions").then((module) => {
      const loggedUserRole = module.loggedUserRole;
      setUserRole(loggedUserRole());
    });
  }, []);

  return (
    <div className="flex flex-wrap my-3 -mx-3">
      <div className="w-full p-6 mx-auto">
        {userRole && userRole === 'Superuser' ? (
          <TagStatistcs userRole={userRole} graph={false} priority={true} tabular={true} />
        ) : (
          <div
          key={`By-Priority`}>
          <TagStatistcs userRole={userRole} priority={true} graph={false} tabular={true} />
        </div>
        )}
      </div>
      <div className="w-full p-6 mx-auto">
        <TabGroup>
          <TabList className="flex flex-wrap justify-left space-x-2 rounded-xl p-2">
            {dateRanges.map((range,) => (
              <Tab
                key={range.text}
                className={({ selected }) =>
                  classNames(
                    "w-auto rounded-lg p-4 text-sm font-medium leading-5",
                    "inline-block px-8 py-2 m-0 text-sm font-bold text-center transition-all cursor-pointer border-1 ",
                    selected
                      ? "text-white bg-gradient-to-tl from-blue-600 to-blue-800 shadow-md"
                      : "hover:bg-gray-100 hover:text-gray-800 border-gray"
                  )
                }
              >
                {range.text}
              </Tab>
            ))}
          </TabList>
          {dateRanges.map((range,) => (
            <TabPanel
              key={range.text}>
              <TabItem
                key={range.text} userRole={userRole} start_date={range.start_date} end_date={range.end_date} />
            </TabPanel>
          ))}
        </TabGroup>
      </div>
      <div className="w-full p-6 mx-auto">
        {(userRole === "Superuser") && <BotChart />}
      </div>
    </div>
  );
};

export default Dashboard;
