/* eslint-disable no-undef */
import userImage from "Assets/user.png";
import ComponentLoader from "Components/Loader/ComponentLoader";
import DataTable from "Components/Table/Index";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBrokerProfileQuery } from "store/Actions/broker";

const ViewBroker = () => {
  const nav = useNavigate();
  const [brokerDetails, setBrokerDetails] = useState({});
  const [vessel, setVessel] = useState({});
  const [filter, setFilter] = useState({
    sort: "vesselname",
    order: "asc",
    perPage: 50,
    page: 1,
    searchField: ``,
    searchValue: ``,
  });

  const { id } = useParams();
  const { data, isSuccess, isLoading, isError } = useBrokerProfileQuery({
    id,
    filter,
  });
  useEffect(() => {
    if (isError) {
      nav("/broker");
    }
    if (isSuccess) {
      setBrokerDetails(data?.data?.broker);
      setVessel(data?.data?.vessel);
    }
  }, [data, isSuccess, isError, nav]);

  const columns = useMemo(
      () => [
        {
          header: "",
          accessor: "displaypicture",
          Cell: ({ cell: { value } }) => {
            return (
              <div className="flex w-8/12">
                <img
                  className="ml-4 w-10/12 md:w-6/12 lg:w:6/12"
                  src={value}
                  alt="..."
                />
              </div>
            );
          },
        },
        {
          header: " year",
          accessor: "year",
        },
        {
          header: "Brand",
          accessor: "manufacturer",
        },
        {
          header: "vessel name",
          accessor: "vesselname",
        },
        {
          header: "Vessel ID",
          accessor: "id",
        },
        {
          header: "Display Length",
          accessor: "displaylengthfeet",
        },
      ],
      [filter]);

  return (
    <>
      {" "}
      {isLoading ? (
        <ComponentLoader />
      ) : (
        <div>
          <div className="w-full px-6 mx-auto">
            <div
              className={`min-h-32 relative mt-6 flex items-center overflow-hidden rounded-2xl banner bg-cover bg-center p-0`}
            >
              <span className="absolute inset-y-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue opacity-60"></span>
            </div>
            <div className="relative flex flex-col flex-auto min-w-0 p-4 mx-6 -mt-16 overflow-hidden break-words border-0 shadow-blur dark:shadow-soft-dark-xl dark:bg-gray-950 rounded-2xl bg-white/80 bg-clip-border backdrop-blur-2xl backdrop-saturate-200">
              <div className="flex flex-wrap -mx-3">
                <div className="flex-none w-auto max-w-full px-3">
                  <div className="text-base ease-soft-in-out h-19 w-19 relative inline-flex items-center justify-center rounded-xl text-white transition-all duration-200">
                    <img
                      src={
                        `https://cdn.yachtbroker.org/biopic/${brokerDetails?.profile}`
                        || userImage
                      }
                      alt="profile_image"
                      className="w-full h-full shadow-soft-sm rounded-xl object-cover preview-img"
                    />
                  </div>
                </div>
                <div className="flex-none w-auto max-w-full px-3 my-auto">
                  <div className="h-full">
                    <h5 className="mb-1 dark:text-white">
                      {isLoading
                        ? "Loading"
                        : brokerDetails?.name}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap  p-6 ">
            <div className="max-w-full px-3 lg:flex-0 lg:w-8/12">
              <div className="relative flex flex-col min-w-0 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
                <div className="flex items-center px-6 py-4 border-b border-solid rounded-t-2xl border-b-slate-100">
                  <div className="flex items-center">
                    <div className="mx-4">
                      <a className="leading-normal text-sm text-slate-700 dark:text-white">
                        {brokerDetails?.email || "loading..."}
                      </a>
                    </div>
                  </div>
                  <div className="ml-auto text-right">
                    <span
                      className={`py-2.2 px-3.6 text-xs rounded-1.8 inline-block whitespace-nowrap text-center ${brokerDetails?.status === "active"
                        ? "bg-lime-200 text-lime-600 "
                        : "bg-red-200 text-red-600 "
                        } align-baseline font-bold capitalize leading-none`}
                    >
                      {brokerDetails?.status}
                    </span>
                  </div>
                </div>
                <div className="flex-auto p-6">
                  <div className="mb-1">
                    <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                      <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal border-0 rounded-t-lg text-sm text-inherit">
                        <strong className="text-slate-700 dark:text-white">
                          Broker Name:
                        </strong>{" "}
                        {brokerDetails?.name}
                      </li>
                      <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal border-0 rounded-t-lg text-sm text-inherit">
                        <strong className="text-slate-700 dark:text-white">
                          Broker Id:
                        </strong>{" "}
                        {brokerDetails?.related_entity_id}
                      </li>
                      <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal border-0 rounded-t-lg text-sm text-inherit">
                        <strong className="text-slate-700 dark:text-white">
                          Brokerage Id:
                        </strong>{" "}
                        {brokerDetails?.parent_id}
                      </li>
                      <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal border-0 rounded-t-lg text-sm text-inherit">
                        <strong className="text-slate-700 dark:text-white">
                          Number Of Vessels:
                        </strong>{" "}
                        {vessel?.total || "0"}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap p-6  my-6 -mx-3">
            <div className="w-full max-w-full px-3 flex-0">
              <div className="relative flex flex-col p-6 min-w-0 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
                {vessel.data ? (
                  <>
                    <h3 className="mb-1 text-bold dark:text-white">Vessels</h3>
                    <DataTable
                      ListQuery={{ data: vessel, isLoading }}
                      filterProps={{ filter, setFilter }}
                      columns={columns}
                      sortingField={["vesselname", "id", "DisplayLengthFeet"]}
                      searchFields={["vesselname", "manufacturer", "id"]}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewBroker;
