import Select from "react-select";
import makeAnimated from "react-select/animated";

import DataTable from "Components/Table/Index";
import { useEffect, useMemo, useState } from "react";



const debounce = (func, delay) => {
  let timeoutId;

  return function (...args) {
    const later = () => {
      timeoutId = null;
      func.apply(this, args);
    };

    clearTimeout(timeoutId);

    timeoutId = setTimeout(later, delay);
  };
};
const animatedComponents = makeAnimated();
const VesselsAnalytics = ({
  ListQuery,
  filterProps,
  columns,
  sortingField,
  searchFields,
  brokerList,
  SearchId,
  searchPlaceHolder,
  searchByBrokerage = false,
  searchByGuest = false,
}) => {
  const { filter, setFilter } = filterProps;
  const [userRole, setUserRole] = useState(``);
  const selectBroker = (val) => {
    const valuesString = val?.map((item) => item.value).join(",");
    setFilter(() => ({ ...filter, broker_name: valuesString }));
  };

  const [brokerOptions, setBrokerOptions] = useState([]);
  useEffect(() => {
    setBrokerOptions(() => []);
    brokerList?.data.map((broker) => {
      setBrokerOptions((brokerOptions) => [
        ...brokerOptions,
        { value: broker, label: broker },
      ]);
    });
    import("utils/functions").then((module) => {
      const loggedUserRole = module.loggedUserRole;
      setUserRole(loggedUserRole());
    });
  }, [brokerList]);

  const debounceFilter = useMemo(
    () =>
      debounce((key, val) => {
        setFilter((prev) => ({
          ...prev,
          [key]: val,
        }));
      }, 500),
    [setFilter]
  );
  return (
    <div>
      {" "}
      <div className="flex w-full gap-4 p-6 pb-0 max-w-6/12 ">
        <div className="flex w-full gap-4 max-w-1/2">
          {userRole === "Admin" || userRole === "AdminOffice" ? (
            <div className="flex w-full">
              <Select
                isMulti
                placeholder="Select a Broker"
                options={brokerOptions}
                components={animatedComponents}
                className="basic-multi-select focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                classNamePrefix="select"
                onChange={selectBroker}
              />
            </div>
          ) : (
            <></>
          )}

          {SearchId && (
            <input
              className={`focus:shadow-soft-primary-outline max-w-1/2 dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-cyan-300 focus:outline-none`}
              placeholder={searchPlaceHolder || "Search By Vessel ID"}
              onChange={(e) => {
                debounceFilter("vessel_id", e.target.value);
              }}
            />
          )}
          {searchByBrokerage && (
            <input
              className={`focus:shadow-soft-primary-outline max-w-1/2 dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-cyan-300 focus:outline-none`}
              placeholder={"Search by Brokerage"}
              onChange={(e) => {
                debounceFilter("brokerage", e.target.value);
              }}
            />
          )}
          {searchByGuest && (
            <input
              className={`focus:shadow-soft-primary-outline max-w-1/2 dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-cyan-300 focus:outline-none`}
              placeholder={"Search by Guest"}
              onChange={(e) => {
                debounceFilter("guest_id", e.target.value);
              }}
            />
          )}
        </div>
        <div className="my-auto ml-auto"></div>
      </div>
      <DataTable
        ListQuery={ListQuery}
        filterProps={filterProps}
        columns={columns}
        sortingField={sortingField}
        searchFields={searchFields}
      />
    </div>
  );
};

export default VesselsAnalytics;
