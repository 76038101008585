import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { Fragment } from "react";

const MoreInfoModal = ({ closeModal, isOpen, modalData }) => {
  const title = modalData && modalData?.data ? 'More Information' : (modalData ? 'Message' : null);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeModal}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle
                  as="h3"
                  className="text-2xl  leading-6 text-blue-800 font-bold "
                >
                  {title}
                </DialogTitle>
                <div className="mt-2 space-y-4">

                  {modalData && modalData.data ? (
                    <>
                      <div className="text-lg font-semibold mb-4">
                        {modalData?.data?.info}
                      </div>

                      <div className="text-gray-600 mb-2">
                        <span className="font-semibold">city:</span>{" "}
                        {modalData?.city?.name}
                      </div>
                      <div className="text-gray-600 mb-2">
                        <span className="font-semibold">country:</span>{" "}
                        {modalData?.country?.name}
                      </div>

                      <div className="text-gray-600 mb-2">
                        <span className="font-semibold">region:</span>{" "}
                        {modalData?.region?.name}
                      </div>
                      <div className="text-gray-600 mb-2">
                        <span className="font-semibold">browser:</span>{" "}
                        {modalData?.browser?.name}
                      </div>
                      <div className="text-gray-600 mb-2">
                        <span className="font-semibold">IP:</span> {modalData?.ip}
                      </div>
                    </>) : (
                    <div className="text-lg text-sm mb-4">
                      {modalData}
                    </div>
                  )}
                </div>

                <div className="mt-4 flex justify-end">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent p-2 bg-blue-100  text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MoreInfoModal;
