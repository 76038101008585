import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAdminVisitorDetailsQuery } from "store/Actions/adminAnalytics";
const VisitorsDetailsAnalytics = () => {
  const { deviceId } = useParams();

  const [visitorData, setVisitorData] = useState({});
  const { data } = useAdminVisitorDetailsQuery({ deviceId });
  useEffect(() => {
    setVisitorData(data?.data?.visitorAnalytics[0]);
  }, [data]);

  return (
    <div className="flex flex-wrap my-3 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 p-2 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                  Visitor Data
                </h5>
                <div className="">
                  <span className="font-semibold">Id:</span>
                  {visitorData?.device_id}
                </div>
              </div>
            </div>
          </div>
          <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />

          <div className="flex justify-between px-6 my-2">
            <div className="w-1/3 ">
              <div className="">
                <span className="font-semibold">Name:</span>Visitor
              </div>
              <div className="">
                <span className="font-semibold">City:</span>
                {visitorData?.city}
              </div>
              <div className="">
                <span className="font-semibold">Mobile:</span>
                {visitorData?.mobile}
              </div>
            </div>
            <div className="w-1/3">
              <div className="">
                <span className="font-semibold">Email:</span>
                {visitorData?.email}
              </div>
              <div className="">
                <span className="font-semibold">Region:</span>
                {visitorData?.region}
              </div>
              <div className="">
                <span className="font-semibold">Full address:</span>
                {visitorData?.ip}
              </div>
            </div>
            <div className="w-1/3">
              <div className="">
                <span className="font-semibold">IP:</span>
                {visitorData?.ip}
              </div>
              <div className="">
                <span className="font-semibold">Country:</span>
                {visitorData?.country}
              </div>
            </div>
          </div>
          <h5 className="mb-2 dark:text-white font-bold text-xl text-blue-800  px-6 ">
            Access History
          </h5>

          <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
          <div className="flex justify-between px-6 my-2 ">
            <div className="w-1/3">
              <div className="">
                <span className="font-semibold">Browser:</span>
                {visitorData?.browser}
              </div>
              <div className="">
                <span className="font-semibold">Longitude:</span>
                {visitorData?.longitude}
              </div>
              <div className="">
                <span className="font-semibold">Count:</span>
                {visitorData?.count}
              </div>
            </div>
            <div className="w-1/3">
              <div className="">
                <span className="font-semibold">Version:</span>
                {visitorData?.browser_version}
              </div>
              <div className="">
                <span className="font-semibold">latitude:</span>
                {visitorData?.latitude}
              </div>
              <div className=""></div>
            </div>
            <div className="w-1/3">
              <div className=""> </div>
              <div className="">
                <span className="font-semibold">OS:</span>
                {visitorData?.os}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorsDetailsAnalytics;
