import ColumnChart from "./Tags/ColumnChart";
import { useAdminQuery, useBrokerageQuery, useBrokerQuery } from "store/Actions/tagStatistics";
import TagWiseInquiries from "./Tags/TagWiseInquiries";
import SkeletonLoader from "Components/Loader/SkeletonLoader";
const TagStatistcs = (props) => {
  const {userRole, tabular, priority, graph } = props;

  const adminQuery = useAdminQuery({}, { skip: userRole !== "Superuser" });
  const brokerageQuery = useBrokerageQuery({}, { skip: (userRole !== "Admin" && userRole !== "OfficeAdmin") });
  const brokerQuery = useBrokerQuery({}, { skip: userRole !== "Broker" });

  let isLoading, data;
  switch (userRole) {
    case 'Superuser':
      data = adminQuery.data?.data;
      isLoading = adminQuery.isLoading;
      break;
    case 'Admin':
    case 'OfficeAdmin':
      data = brokerageQuery.data?.data;
      isLoading = brokerageQuery.isLoading;
      break;
    case 'Broker':
      data = brokerQuery.data?.data;
      isLoading = brokerQuery.isLoading;
      break;
    default:
      data = null;
      isLoading = false;
      break;
  }

  return (
    <div className="max-h-8/10">
      <div className="flex flex-wrap mt-6 -mx-3 ">
        {graph && graph === true ? (
          <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-4/12 lg:flex-none">
            {userRole && <ColumnChart data={data} isLoading={isLoading} />}
          </div>
        ) : null}

        {tabular && tabular === true ? (
          <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-12/12 lg:flex-none">
            {isLoading ? (
              <SkeletonLoader />
            ) : (
              <TagWiseInquiries role={userRole} tags={data} priority={priority} />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TagStatistcs;
