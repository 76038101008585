// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating roleManagement by enhancing and injecting endpoints using serverApi
const roleManagement = serverApi
  .enhanceEndpoints({ tagTypes: ["role"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of roles
      roleList: builder.query({
        query(filter) {
          // Encode the object's data into URL parameters
          const queryString = new URLSearchParams(filter?.column_search).toString();
          return {
            url: `list-role?page=${filter.page || 1}&per_page=${filter?.perPage || 50
              }&sort_field=${filter?.sort || "name"}&sort_order=${filter?.order || "asc"
              }&search_value=${filter.searchValue || ""}&search_field=${filter.searchField || ""
              }&${queryString}`,
            method: "GET",
          };
        },
        // Provides role tags for caching role list data
        providesTags: ["role"],
      }),
    }),
  });

// Destructuring roleManagement to get individual hooks for components
export const { useRoleListQuery } = roleManagement;
