import { useEffect, useState } from "react";
import CategoryChart from "./CategoryChart";
import CategoryVesselsChart from "./CategoryVesselsChart";
import { generateDateRanges } from "utils/functions";

const CategoriesAnalytics = () => {
  const dateRanges = generateDateRanges();
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [categoryId, setCategoryId] = useState(null);

  const [filter, setFilter] = useState({
    start_date: ``,
    end_date: ``,
  });
  const setDate = (index) => {
    setSelectedBtn(index);
  };

  useEffect(() => {
    const selectedRange = generateDateRanges().find((item) => item.index === selectedBtn); // Use `find` for a single match    
    if (selectedRange) {
      setFilter((filter) => ({ ...filter,page:1, start_date: selectedRange.start_date, end_date: selectedRange.end_date }));
    }
  }, [selectedBtn]);
  return (
    <div className="flex flex-wrap my-6 -mx-3 ">
      <div className="w-full max-w-full px-3 flex-0 ">
        <div className="relative flex flex-col min-w-0 p-2 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div className="flex gap-4 p-6 pb-0 pt-1 max-w-3/12">
                <h5 className="mb-2 dark:text-white font-bold text-3xl text-blue-800">
                  Categories Analytics
                </h5>
              </div>
              <div className="flex gap-4 pl-6 pb-0 pt-1 max-w-9/12">
                {dateRanges.map((range,) => (
                  <span
                    key={range.text}
                    onClick={() => setDate(range.index)}
                    className={`w-auto rounded-lg p-4 text-sm font-medium leading-5 inline-block px-4 py-2 m-0 text-sm font-bold text-center transition-all cursor-pointer border-1 ${(selectedBtn === range.index ? "text-white border-blue-100 bg-gradient-to-tl from-blue-600 to-blue-800" : "hover:bg-gray-100 hover:text-gray border-gray")}`}
                  >
                    {range.text}
                  </span>
                ))}
              </div>
            </div>
            <div className="my-auto flex ml-auto lg:mt-0 ">
              {categoryId ? (
                <div>
                  <span
                    className="text-blue-800 hover:underline cursor-pointer"
                    onClick={() => setCategoryId(null)}
                  >
                    Category
                  </span>{" "}
                  /{" "}
                  <span dangerouslySetInnerHTML={{ __html: categoryId }}></span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="overflow-y-auto">
            {categoryId ? (
              <CategoryVesselsChart categoryId={categoryId} filter={filter} />
            ) : (
              <CategoryChart setCategoryId={setCategoryId} filter={filter} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesAnalytics;
