// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";


// Creating tagStatistics by enhancing and injecting endpoints using serverApi
const tagStatistics = serverApi
  .enhanceEndpoints({ tagTypes: ["tagStatistics"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      admin: builder.query({
        query() {
          return {
            url: `top-tags`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        keepUnusedDataFor: 1,
        providesTags: ["tagStatistics"],
      }),
      brokerage: builder.query({
        query() {
          return {
            url: `top-tags`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        keepUnusedDataFor: 1,
        providesTags: ["tagStatistics"],
      }),
      broker: builder.query({
        query() {
          return {
            url: `top-tags`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        keepUnusedDataFor: 1,
        providesTags: ["tagStatistics"],
      }),
      tagLatestInquiries: builder.mutation({
        query(body) {
          // //eslint-disable-next-line no-console
          // console.table(body);
          return {
            url: `tag-base-latest-inquiries-${body.roleLower}/?limit=5`,
            method: "POST",
            body: { tagIds: body.ids },
          };
        },
        // Invalidates broker tags after creating a new broker
        providesTags: ["tagStatistics"],
      }),
    }),
  });

export const {
  useAdminQuery,
  useBrokerageQuery,
  useBrokerQuery,
  useTagLatestInquiriesMutation
} = tagStatistics;