import Flatpickr from "react-flatpickr";
import DataTable from "Components/Table/Index"
import moment from "moment";
import { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useAdminMyVisitsQuery } from "store/Actions/adminAnalytics";
import { currentDate, currentMonth, currentYear } from "utils/functions";

const AccountVisits = () => {
    const { deviceId } = useParams();
    const [searchParams] = useSearchParams();
    const [filter, setFilter] = useState({
        sort: "visitor_counts",
        order: "desc",
        perPage: 50,
        page: 1,
        start_date: searchParams.get("from") ? searchParams.get("from") : `${currentMonth()}/01/${currentYear()}`,
        end_date: searchParams.get("to") ? searchParams.get("to") : `${currentMonth()}/${currentDate()}/${currentYear()}`,
    });
    const { data: list, isFetching } = useAdminMyVisitsQuery({ deviceId, filter });

    const setDate = (newValue) => {
        const startDate = moment(newValue[0]).format("MM-DD-YYYY");
        const endDate = moment(newValue[1]).format("MM-DD-YYYY");

        setFilter((prev) => {
            return {
                ...prev,
                start_date: startDate,
                end_date: endDate,
            };
        });
    };

    const columns = [
        {
            header: "vessel id",
            accessor: "vessel_id",
            Cell: ({ cell: { row } }) => {
                const name = `${row.original.vessel_id} ${row.original.brokerage_name} - ${row.original.display_length_feet}' ${row.original.manufacturer}  ${row.original.year} `;
                return (
                    <div className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit">
                    <Link
                        to={`/vessel-details/view/${row.original.vessel_id}?from=${filter.start_date}&to=${filter.end_date}`}
                        className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit"
                    >
                        <img
                            src={row?.original?.vessel_image_url}
                            className=" w-20 h-full shadow-soft-sm rounded-sm object-cover preview-img "
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: name }}
                            className="text-lg"
                        ></div>
                    </Link>
                    {row.original.visitor_counts > '1' ? (<span title="Visited multiple times in a day" className="border border-red-500 bg-white text-xs px-3 py-1 ml-2 rounded-full">Interested</span>) : null}
                    </div>
                );
            },
        },
        {
            header: "Page Views",
            accessor: "visitor_counts",
        },
        {
            header: "Date",
            accessor: "updated",
        }
    ];

    return (
        <div className="flex flex-wrap my-3 -mx-3">
            <div className="w-full max-w-full px-3 flex-0">
                <div className="relative flex flex-col min-w-0 break-words bg-white border-0 p-6 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
                    <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                        <div className="lg:flex">
                            <div className="text-lg text-blue-800 flex justify-between px-3">
                                <span>Visits</span>
                            </div>
                            <div className="my-auto flex ml-auto lg:mt-0 ">
                                <div className="my-auto ml-auto">
                                    <div className="flex">
                                        <Flatpickr
                                            options={{
                                                mode: "range",
                                                dateFormat: "m-d-Y",
                                                defaultDate: [
                                                    `${filter.start_date}`,
                                                    `${filter.end_date}`,
                                                ],
                                            }}
                                            fromdateid="DashboardEndDatePicker"
                                            selectvalue={[]}
                                            className="form-control clickable  focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-fit appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mx-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                                            onClose={setDate}
                                            placeholder="Select Date Range 🗓️"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            ListQuery={{ data: list?.data, isLoading: isFetching }}
                            filterProps={{ filter, setFilter }}
                            columns={columns}
                            sortingField={["vessel_id", 'visitor_counts']}
                            SearchId={true}
                            searchPlaceHolder="Vessel Id"
                            hideFilter={true}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AccountVisits