import DataTable from "Components/Table/Index";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import {
  useDeleteSearchMutation,
  useSearchHistoryListQuery,
} from "store/Actions/searchHistory";
import Swal from "sweetalert2";
import SearchHistoryEditModal from "./SearchHistoryEditModal";
import { generateDateRanges } from "utils/functions";
const customStyles = {
  valueContainer: (base) => ({
    ...base,
    justifyContent: "center",
  }),
};
const SearchHistory = () => {
  const dateRanges = generateDateRanges();
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [id, setId] = useState(null);
  let [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState({
    sort: "created_at_list",
    order: "desc",
    perPage: 50,
    page: 1,
    searchField: ``,
    searchValue: ``,
    user_type: ``,
    start_date: ``,
    end_date: ``
  });

  const { data, refetch, isFetching, isLoading, isError } = useSearchHistoryListQuery(filter, { skip: filter.start_date == `` });
  const setDate = (index) => {
    setSelectedBtn(index);
  };
  const [deleteSearch] = useDeleteSearchMutation();

  function closeModal() {
    setIsOpen(false);
    setId(null);
  }

  function openModal(id, user_id) {
    setId({ id: id, user_id: user_id });
    setIsOpen(true);
  }
  const userType = [
    { value: "All", label: "All" },
    { value: "Visitor", label: "Visitor" },
    { value: "Guest", label: "Guest" },
  ];
  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSearch(id).then((res) => {
          if (res.error) {
            toast.error(res.error.data.message);
          } else {
            toast.success(res.data?.message);
            if (data?.data?.data.length === 1) {
              setFilter((filter) => ({
                ...filter,
                page: data.data.last_page - 1,
              }));
            } else {
              refetch();
            }
          }
        });
      }
    });
  };
  useEffect(() => {
    const selectedRange = generateDateRanges().find((item) => item.index === selectedBtn); // Use `find` for a single match
    // eslint-disable-next-line no-console
    if (selectedRange) {
      setFilter((filter) => ({ ...filter, page: 1, start_date: selectedRange.start_date, end_date: selectedRange.end_date }));
    }
  }, [selectedBtn]);


  const columns = useMemo(
    () => [
      {
        header: "name",accessor: "name",
        Cell: ({ cell: { value } }) => {
          return <span>{value || "Visitor"}</span>;
        },
      },
      {
        header: "Email", accessor: "email",
        Cell: ({ cell: { value } }) => {
          return <span>{value || "NA"}</span>;
        },
      },
      {header: "ip", accessor: "ip"},

      { header: "Search param", accessor: "data",
        Cell: ({ cell: { value } }) => {
          return (
            <div>
              <p className=" leading-normal text-xl flex flex-wrap max-w-60">
                {Object.keys(value).map((key) => (
                  <span
                    key={key}
                    className={` py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-bold bg-slate-200 text-slate-700 iiiii leading-none m-1 flex flex-wrap w-fit`}
                  >
                    <span className="font-normal">{key}-</span>
                    <span className="font-semibold">{value[key]}</span>
                  </span>
                ))}
              </p>{" "}
            </div>
          );
        },
      },
      {
        header: "Geo Location",
        accessor: "latitude",
        Cell: ({ cell: { row } }) => {
          return (
            <span className="flex flex-col">
              {" "}
              <span>{row.original.longitude}</span>{" "}
              <span>{row.original.latitude}</span>{" "}
            </span>
          );
        },
      },
      { header: "city", accessor: "city.name"},
      { header: "State", accessor: "region.name"},
      { header: "country", accessor: "country.name"},
      { header: "created at", accessor: "created_at_list"},
      { header: "Updated at", accessor: "updated_at_list"},
      { header: "Action", accessor: "id",
        Cell: ({ cell: { value, row } }) => {
          return (
            <div className="flex gap-2  items-center font-semibold ">
              <span
                className="cursor-pointer hover:text-blue-600 hover:underline "
                // onClick={() => nav(`/brokerage/edit/${value}`)}
                onClick={() => {
                  openModal(value, row.original.user_id);
                }}
              >
                Edit
              </span>
              {" | "}
              <span
                className="cursor-pointer hover:text-red-600 hover:underline "
                onClick={() => onDelete(value)}
              >
                Delete
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]);

  return (
    <div className="flex flex-wrap my-3 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 p-2 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div className="flex gap-4 p-6 pb-0 pt-1 max-w-3/12">
                <h5 className="mb-2 dark:text-white font-bold text-3xl text-blue-800">
                  Search History
                </h5>
              </div>
              <div className="flex gap-4 pl-6 pb-0 pt-1 max-w-9/12">
                {dateRanges.map((range,) => (
                  <span
                    key={range.text}
                    onClick={() => setDate(range.index)}
                    className={`w-auto rounded-lg p-4 text-sm font-medium leading-5 inline-block px-4 py-2 m-0 text-sm font-bold text-center transition-all cursor-pointer border-1 ${(selectedBtn === range.index ? "text-white border-blue-100 bg-gradient-to-tl from-blue-600 to-blue-800" : "hover:bg-gray-100 hover:text-gray border-gray")}`}
                  >
                    {range.text}
                  </span>
                ))}
              </div>
            </div>
            <div className="lg:flex">
              <div className="flex gap-4 pl-6 pb-0 pt-1 max-w-9/12 w-48">
                <Select
                  isSearchable={false}
                  placeholder="All"
                  options={userType}
                  name="condition"
                  styles={customStyles}
                  defaultValue={"All"}
                  className="basic-multi-select focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg  bg-white bg-clip-padding font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none z-30"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setFilter((prev) => ({
                      ...prev,
                      user_type: e ? e.value : null,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className=" overflow-x-auto p-3">
            <DataTable
              ListQuery={{
                data: data?.data,
                isLoading: isLoading,
                isFetching: isFetching,
                isError: isError
              }}
              filterProps={{ filter, setFilter }}
              columns={columns}
              sortingField={[
                "name",
                "email",
                "ip",
                "city.name",
                "region.name",
                "country.name",
                "created_at_list",
                "updated_at_list",
              ]}
              searchFields={["name", "email", "ip"]}
            />
          </div>
        </div>
      </div>
      <SearchHistoryEditModal isOpen={isOpen} closeModal={closeModal} id={id} />
    </div >
  );
};

export default SearchHistory;
