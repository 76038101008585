import ComponentLoader from "Components/Loader/ComponentLoader";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import "../../Assets/css/soft-ui-dashboard-tailwind.css";
import HighChart from "Components/Chart/HighChart";
import { useAdminVesselsGraphQuery } from "store/Actions/adminAnalytics";
import { useParams } from "react-router-dom";
import moment from "moment";

const VesselsDetailGraphLineChart = ({filterProps}) => {

    const [chartOptions, setChartOptions] = useState(null);
    const [dateRange, setDateRange] = useState([])
    const [sData, setSData] = useState([
        {
            name: "visitors",
            data: []
        },
        {
            name: "guest",
            data: []
        },
        {
            name: "Inquiry",
            data: []
        },
    ]);

    const { vesselId } = useParams();
    const {filter} = filterProps
    const { data, isLoading } = useAdminVesselsGraphQuery({ vesselId, filter });
    useEffect(() => {

        function getDates(startDate, endDate) {
            // eslint-disable-next-line no-console
            // console.log(startDate);

            const dateArray = [];
            let currentDate = moment(startDate, 'MM_DD_YYYY');
            endDate = moment(endDate, 'MM_DD_YYYY');

            while (currentDate <= endDate) {
                dateArray.push(currentDate.format('YYYY-MM-DD'));
                currentDate = currentDate.add(1, 'days');
            }

            return dateArray;
        }

        const dates = getDates(filter.start_date, filter.end_date);
        setDateRange(dates);

        if(data?.data?.vesselData){
            const newData = [
                {
                    name: "visitors",
                    data: []
                },
                {
                    name: "guest",
                    data: []
                },
                {
                    name: "Inquiry",
                    data: []
                },
            ];
            dates.forEach(date => {
                const foundData = data?.data?.vesselData?.find(item => item?.Date === date);
                if (foundData) {
                    newData[0].data.push(parseInt(foundData.visitor_counts));
                    newData[1].data.push(parseInt(foundData.guest_counts));
                } else {
                    newData[0].data.push(0);
                    newData[1].data.push(0);
                }
                const found = data?.data?.inquiryData?.find(item => item?.Date === date);
                if (found) {
                    newData[2].data.push(parseInt(found.inquiry_count));
                } else {
                    newData[2].data.push(0);
                }

            });
            
            setSData(newData);
        }
    }, [data,filter]); 

    useEffect(() => {
                
      if (dateRange.length) {
        //   const categories = data?.data?.map((val) => val.brokerage_name);
    
          const options = {
            chart: {
                type: 'spline'
            },
            title: {
                text: 'Vessels Details'
            },
            // subtitle: {
            //     text: 'Graph view for Visitor , guest and Vessels Inquiries  '
            // },
            xAxis: {
                categories:dateRange
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
                split: false,
                padding: 5
              },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Counts'
                },
                stackLabels: {
                    enabled: true,
                    formatter: function () {
                      return Highcharts.numberFormat(this.total, 0, "", "");
                    },
                  },
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            series: sData
        }
    
          setChartOptions(options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data,dateRange]);
    
      return (
        <>
          {" "}
          {isLoading ? (
            <ComponentLoader />
          ) : (
            <HighChart chartOptions={chartOptions} />
          )}
        </>
      );
}

export default VesselsDetailGraphLineChart