import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SkeletonLoaderFullpage from "Components/Loader/SkeletonLoaderFullpage";
import DataTable from "Components/Table/Index";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  useBrokerListQuery,
} from "store/Actions/broker";
import { useSwitchToAnyUserMutation } from "store/Actions/switchAction";

import { loggedBackId, loggedUserRole } from "utils/functions";

const BrokerList = () => {
  const broadcast = new BroadcastChannel('auth_channel');
  const [filter, setFilter] = useState({
    sort: "name",
    order: "asc",
    perPage: 50,
    page: 1,
    searchField: ``,
    searchValue: ``,
  });
  const { data, isLoading, refetch } = useBrokerListQuery(filter);

  const nav = useNavigate();

  const [switchToAnyBroker, switchToAnyBrokerAPI] =
    useSwitchToAnyUserMutation();
  const switchUser = (id) => {
    switchToAnyBroker(id).then((res) => {
      if (res.error) {
        toast.error(res.error.data.message);
      } else {
        broadcast.postMessage({ type: 'SwitchedTo' });
        localStorage.setItem("token", res.data?.data?.token);
        localStorage.setItem("userInfo", JSON.stringify(res.data?.data));
        toast.success(res?.data?.message);
        nav("/");
      }
    });

  };
  const columns = useMemo(
    () => [
      {
        header: "User ID",
        accessor: "related_entity_id",
      },
      {
        header: "Name",
        accessor: "name"
      },
      {
        header: "email",
        accessor: "email",
      },
      {
        header: "Brokerage",
        accessor: "brokerage_name",
      },

      {
        header: "Action",
        accessor: "uid",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="flex gap-2  items-center  font-semibold  ">
              {loggedBackId() || loggedUserRole() != 'Superuser' ? null : (
                <><span
                  className="cursor-pointer hover:text-green-600  hover:underline "
                  onClick={() => switchUser(value)}
                  title="Impersonate to Account"
                >
                  <FontAwesomeIcon icon={faUserSecret} />
                </span>{" | "}</>
              )}
              <span
                className="cursor-pointer hover:text-orange-600 hover:underline"
                onClick={() => nav(`/broker/view/${value}`)}
              >
                View
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <div className="flex flex-wrap my-3 -mx-3">
        <div className="w-full max-w-full px-3 flex-0">
          <div className="relative flex flex-col min-w-0 break-words p-6 bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
              <div className="lg:flex">
                <div>
                  <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                    Users
                  </h5>
                  <p className="mb-0 leading-normal text-sm"></p>
                </div>
              </div>
            </div>

            <DataTable
              ListQuery={{ data: data?.data, isLoading, refetch }}
              filterProps={{ filter, setFilter }}
              columns={columns}
              sortingField={["name", "status","brokerage_name"]}
              searchFields={["name", "email",'brokerage_name']}
            />
          </div>
        </div>
      </div>
      <SkeletonLoaderFullpage
        loading={
          switchToAnyBrokerAPI?.isLoading
        }
      />

    </>
  );
};

export default BrokerList;
