import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const ActionReport = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    const user_code = Number(searchParams.get("user_code"));
    const role = searchParams.get("role");

    const userInfo = { token, user_code, role };
    const authUser = [1000, 1001, 1002, 1003, 1004,1005];
    if (authUser.includes(Number(user_code))) {
      localStorage.setItem("token", token);
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
  }, [searchParams]);
  return <></>;
};

export default ActionReport;
