import BrokerageAnalytics from "./BrokerageAnalytics/Index";
import BrokerAnalytics from "./BrokerAnalytics/Index";
import VesselsAnalytics from "./VesselsAnalytics/Index";
const TabItem = ({ userRole, start_date, end_date }) => {

  return (
    <div className="max-h-8/10">
      <div className="flex flex-wrap mt-6 -mx-3 ">
        {(userRole === "Superuser") && <BrokerageAnalytics startsAtDate={start_date} endsAtDate={end_date} />}
        {(userRole === "Admin" || userRole === "OfficeAdmin") && <BrokerAnalytics startsAtDate={start_date} endsAtDate={end_date} />}
        {(userRole != "Guest") && <VesselsAnalytics startsAtDate={start_date} endsAtDate={end_date} />}
      </div>
    </div>
  );
};

export default TabItem;
