import ComponentLoader from "Components/Loader/ComponentLoader";
import Pagination from "Components/Pagination/Pagination";
import { perPageOptions } from "utils/data";
import ListTable from "./ListTable";

const MiniTAble = ({
  ListQuery,
  filterProps,
  columns,
  sortingField,
  searchFields,
  hideColumns
}) => {
  const { data, isLoading } = ListQuery;
  const { filter, setFilter } = filterProps;
  const handleSort = (name) => {
    if (filter.sort === name) {
      setFilter((prev) => ({
        ...prev,
        order: prev.order === "asc" ? "desc" : "asc",
      }));
    } else {
      setFilter((prev) => ({ ...prev, sort: name, order: "asc" }));
    }
  };

  return (
    <>
      <div className="dataTable-wrapper">
        {data?.data ? (
          <div className="dataTable-top !p-0">
            <div className="dataTable-dropdown">
              <label>
                <select
                  className="dataTable-selector"
                  value={filter.perPage}
                  onChange={(e) => {
                    setFilter((prev) => ({
                      ...prev,
                      ...{ perPage: e.target.value, page: 1 },
                    }));
                  }}
                >
                  {perPageOptions?.map((option) => (
                    <option key={option.name} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>{" "}
                entries per page
              </label>
            </div>
          </div>
        ) : (
          <div className="dataTable-top !py-1"></div>
        )}
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className=" overflow-auto min-w-full md:max-h-[35vh] ">
            {data && data?.data?.length ? (
              <ListTable
                columns={columns}
                data={data?.data}
                filter={filter}
                setFilter={setFilter}
                handleSort={(e) => handleSort(e)}
                sortingField={sortingField}
                searchFields={searchFields}
                hideColumns={hideColumns}
              />
            ) : (
              <div className="text-2xl text-red-900 text-center font-semibold p-5">
                Nothing to show !
              </div>
            )}
          </div>
        )}
        {data?.data?.length ? (
          <div className="dataTable-bottom mt-1 !py-0">
            <div className="dataTable-info ">
              {isLoading
                ? "Loading..."
                : `Showing ${data?.from ? data?.from : (data?.current_page - 1) * data?.per_page + 1} to ${data?.to ? data?.to : data?.total < data?.current_page * data?.per_page ? data?.total : data?.current_page * data?.per_page} of ${data?.total} entries`}
            </div>
            <Pagination
              currentPage={data?.current_page}
              totalCount={data?.total}
              pageSize={data?.per_page}
              onPageChange={(page) => {
                setFilter((prev) => ({
                  ...prev,
                  ...{ page: page },
                }));
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default MiniTAble;