import VesselsAnalytics from "pages/Reports/VesselsAnalytics";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAdminVesselsQuery } from "store/Actions/adminAnalytics";
import { generateDateRanges } from "utils/functions";

const AdminVesselsAnalytics = () => {
  const dateRanges = generateDateRanges();
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [filter, setFilter] = useState({
    sort: "visitor_counts",
    order: "desc",
    perPage: 50,
    page: 1,
    start_date: ``,
    end_date: ``
  });
  const { data, isFetching } = useAdminVesselsQuery(filter, { skip: filter.start_date == `` });
  const setDate = (index) => {
    setSelectedBtn(index);
  };
  useEffect(() => {
    const selectedRange = generateDateRanges().find((item) => item.index === selectedBtn); // Use `find` for a single match
    
    setFilter((filter) => ({ ...filter,page:1, start_date: selectedRange.start_date, end_date: selectedRange.end_date }));
  }, [selectedBtn]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(
    () => [
      {
        header: "vessel id",
        accessor: "vessel_id",
        Cell: ({ cell: { row } }) => {
          const name = `${row.original.vessel_id} ${row.original.brokerage_name} - ${row.original.display_length_feet}' ${row.original.manufacturer}  ${row.original.year} `;
          return (
            <Link
              to={`/vessel-details/view/${row.original.vessel_id}?from=${filter.start_date}&to=${filter.end_date}`}
              className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit"
            >
              <img
                src={row?.original?.vessel_image_url}
                className=" w-20 h-full shadow-soft-sm rounded-sm object-cover preview-img "
              />
              <div
                dangerouslySetInnerHTML={{ __html: name }}
                className="text-lg "
              ></div>
            </Link>
          );
        },
      },
      {
        header: "Visitors",
        accessor: "visitor_counts",
        Cell: ({ cell: { value } }) => {
          return <span className={``}>{value.toLocaleString()}</span>;
        },
      },
      {
        header: "Guests",
        accessor: "guest_counts",
        Cell: ({ cell: { value } }) => {
          return <span className={``}>{value.toLocaleString()}</span>;
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  return (
    <div className="flex flex-wrap my-3 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 p-2 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div className="flex gap-4 p-6 pb-0 pt-1 max-w-3/12">
                <h5 className="mb-2 dark:text-white font-bold text-3xl text-blue-800">
                  Vessel Analytics
                </h5>
              </div>
              <div className="flex gap-4 pl-6 pb-0 pt-1 max-w-9/12">
                {dateRanges.map((range,) => (
                  <span
                    key={range.text}
                    onClick={() => setDate(range.index)}
                    className={`w-auto rounded-lg p-4 text-sm font-medium leading-5 inline-block px-4 py-2 m-0 text-sm font-bold text-center transition-all cursor-pointer border-1 ${(selectedBtn === range.index ? "text-white border-blue-100 bg-gradient-to-tl from-blue-600 to-blue-800" : "hover:bg-gray-100 hover:text-gray border-gray")}`}
                  >
                    {range.text}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <VesselsAnalytics
            ListQuery={{ data: data?.data || false, isLoading: isFetching }}
            filterProps={{ filter, setFilter }}
            columns={columns}
            sortingField={[
              "vessel_id",
              "visitor_counts",
              "guest_counts",
            ]}
            SearchId={true}
            searchPlaceHolder="Search By Vessel ID"
            searchByBrokerage={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminVesselsAnalytics;
