import MiniTAble from "Components/Table/MiniTAble";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAdminVesselsVisitorQuery } from "store/Actions/adminAnalytics";

const VesselsVisitor = ({ filterProps, totalCount = 0 }) => {
  const [filter, setFilter] = useState({
    perPage: 50,
    page: 1,
    start_date: filterProps?.filter?.start_date,
    end_date: filterProps?.filter?.end_date,
  });
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      end_date: filterProps?.filter?.end_date,
      start_date: filterProps?.filter?.start_date,
    }));
  }, [filterProps.filter.end_date, filterProps?.filter?.start_date]);
  const { vesselId } = useParams();
  const { data, isFetching } = useAdminVesselsVisitorQuery({
    vesselId,
    filter,
  },{
    skip: totalCount < 1,
  });
  const columns = useMemo(
    () => [
      {
        header: " ip",
        accessor: "ip",
        Cell: ({ cell: { row } }) => {
          return (
            <><Link
              to={`/account-details/view/${row.original.device_id}?to=${filter.end_date}&from=${filter.start_date}`}
              className=" text-blue-700 hover:text-blue-800"
            >
              {row.original.ip}
            </Link>
            {row?.original?.interested == '1' ? (<span title="Visited multiple times in a day" className="border border-red-500 bg-white text-xs px-3 py-1 ml-2 rounded-full">Interested</span>) : (
                <></>
              )}
            </>
          );
        },
      },

      {
        header: "Page Views",
        accessor: "visitor_counts",
        Cell: ({ cell: { value } }) => {
          return <span className={``}>{value.toLocaleString()}</span>;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <div className="text-lg text-blue-800 flex justify-between px-3">
        <span>Page Views</span>
        <span>{totalCount}</span>
      </div>
      <MiniTAble
        ListQuery={{ data: data?.data || [], isLoading: isFetching }}
        filterProps={{ filter: filter, setFilter: setFilter }}
        columns={columns}
        sortingField={["created_at"]}
        SearchId={true}
        searchPlaceHolder="Vessel Id"
      />
    </>
  );
};

export default VesselsVisitor;
